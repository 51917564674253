<template>
	<div>
		<router-view />
		<portal-target name="modal1" />
		<portal-target name="modal2" />
		<portal-target name="modal3" />
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
	computed: {
		...mapGetters(['popCount']),
	},

	created() {
		window.backPressedListener = this.backPressedListener;
	},

	methods: {
		...mapMutations({ closePopup: 'closePopup' }),

		backPressedListener() {
			// 0: 무반응, 1: 뒤로가기, 2:앱종료
			if (this.popCount > 0) {
				this.closePopup();
				return 0;
			}

			console.debug(location.pathname);

			if (
				location.pathname === '/kpic/register/postService' ||
				location.pathname === '/kpic/home/user' ||
				location.pathname === '/kpic/home/corp'
			) {
				return 2;
			}

			return 1;
		},
	},
};
</script>
<style>
@import './assets/css/morpheus-common.css';
@import './assets/css/morpheus-style.css';
@import './assets/css/bootstrap.css';
</style>
