<template>
	<portal :to="portalTo">
		<transition name="fade">
			<div class="state_pop" :style="modalStyle">
				<div class="toast_box">
					<p>{{ msg }}</p>
				</div>
			</div>
		</transition>
	</portal>
</template>
<script>
import { mapMutations } from 'vuex';

export default {
	name: 'ModalToast',
	props: {
		portalTo: {
			type: String,
			required: true,
		},
		duration: {
			type: Number,
			default: 1300,
		},
		msg: {
			type: String,
			required: true,
		},
	},
	computed: {
		modalStyle() {
			let result = 100;
			if (this.portalTo === 'modal1') {
				result = 100;
			} else if (this.portalTo === 'modal2') {
				result = 200;
			} else if (this.portalTo === 'modal3') {
				result = 300;
			}
			return { 'z-index': result };
		},
	},
	methods: {
		...mapMutations({ resetToast: 'resetModalState' }),
	},
	mounted() {
		setTimeout(() => {
			this.resetToast();
		}, this.duration);
	},
};
</script>
<style scoped>
.toast_box {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 10px;
	min-width: 300px;
	min-height: 60px;
	padding: 10px;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.toast_box p {
	color: #fff;
	text-align: center;
	line-height: 1.56;
}
</style>
