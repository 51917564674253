<template>
	<div class="m36 wrapper error">
		<div class="bio-pass">
			<p>휴대폰 본인인증</p>
			<span>휴대폰 본인인증 준비중입니다.</span>
			<button type="button" @click="doSkip">건너뛰기</button>
		</div>
	</div>
</template>

<script>
const sampleData = {
	ci: '1111111111111111111111111111111111111111111111111111111111111111133111111111111111111112',
	di: '1111111111111111111111111111111111111111111111111111111111111111',
	name: '홍갈덩',
	sex: 'M',
	hp: '010-0010-0001',
	birth: '19980101',
	national: 1,
};
export default {
	name: 'CertTest',

	methods: {
		doSkip() {
			if (window.appBridge) {
				console.debug('android KPIC_dispatchDeleteDID');
				window.appBridge.dispatch('3000', JSON.stringify(sampleData));
			} else if (
				window.webkit &&
				window.webkit.messageHandlers &&
				window.webkit.messageHandlers.dispatch
			) {
				console.debug('ios KPIC_dispatchDeleteDID');
				window.webkit.messageHandlers.dispatch.postMessage(
					JSON.stringify({ code: 3000, data: JSON.stringify(sampleData) }),
				);
			}
		},
	},
};
</script>
