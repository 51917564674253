<template>
	<portal to="modal3">
		<transition name="fade">
			<div class="loading">
				<div class="spinner">
					<svg viewBox="0 0 100 100">
						<circle cx="50" cy="50" r="20" />
					</svg>
				</div>
			</div>
		</transition>
	</portal>
</template>
<script>
export default {
	name: 'LoadingProgress',
};
</script>
<style scoped>
.loading {
	height: 100%;
	width: 100%;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 300;
	display: flex;
	align-items: center;
	justify-content: center;
}

.spinner {
	width: 90px;
	height: 90px;
}

.spinner svg {
	animation: rotate 1.5s linear infinite;
	height: 100%;
	width: 100%;
}

.spinner circle {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite 0s;
	stroke-linecap: round;
	fill: none;
	stroke-width: 4;
	stroke: #ed4352;
}

@keyframes rotate {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}
	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}
	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}
</style>
