import Vue from 'vue';
import VueRouter from 'vue-router';

import KPICApp from '@/KPICApp';
import RootContainer from '@/RootContainer';
import CertTest from '@/views/CertTest';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'base',
		component: KPICApp,
		// redirect: to => {
		// 	const { query } = to;

		// 	console.debug('== query', query);

		// 	if (!query.LOGIN_YN) {
		// 		return { name: 'Error' };
		// 	}

		// 	// 앱 소개 화면 노출 여부를 확인한다.
		// 	const shownIntoroduce = localStorage.getItem('shownIntoroduce');
		// 	if (!shownIntoroduce) {
		// 		return { name: 'Introduce' };
		// 	}

		// 	if (query.LOGIN_YN === 'Y') {
		// 		if (query.FIDO_YN === 'Y') {
		// 			return { name: 'LoginFingerPrint' };
		// 		} else {
		// 			return { name: 'LoginPassCode' };
		// 		}
		// 	} else {
		// 		return { name: 'RegisterPostService', query: {} };
		// 	}
		// },
	},

	{
		path: '/test/cert',
		name: 'cert',
		component: CertTest,
	},

	{
		path: '/kpic',
		component: RootContainer,
		children: [
			{
				path: 'introduce',
				name: 'Introduce', // 소개화면
				component: () =>
					import(
						/* webpackChunkName: "introduce" */ '@/views/introduce/Introduce.vue'
					),
			},
			{
				path: 'register/postService',
				name: 'RegisterPostService', // 소개화면
				component: () =>
					import(
						/* webpackChunkName: "register" */ '@/views/register/PostService.vue'
					),
			},
			{
				path: 'login/passCode',
				name: 'LoginPassCode', // 패스코드
				component: () =>
					import(/* webpackChunkName: "login" */ '@/views/login/PassCode.vue'),
			},
			{
				path: 'login/fingerPrint',
				name: 'LoginFingerPrint', // 지문등록
				component: () =>
					import(
						/* webpackChunkName: "login" */ '@/views/login/FingerPrint.vue'
					),
			},
			{
				path: 'login/corp',
				name: 'LoginForCorp', // 소개화면
				component: () =>
					import(
						/* webpackChunkName: "login" */ '@/views/login/LoginForCorp.vue'
					),
			},

			{
				path: 'register',
				component: () =>
					import(
						/* webpackChunkName: "register" */ '@/views/register/Container.vue'
					),
				children: [
					{
						path: '',
						name: 'Register',
						component: () =>
							import(
								/* webpackChunkName: "register" */ '@/views/register/sub/Service.vue'
							),
					},
					{
						path: 'passcode',
						name: 'RegisterPassCode', // 등록화면
						component: () =>
							import(
								/* webpackChunkName: "register" */ '@/views/register/sub/PassCode.vue'
							),
					},
					{
						path: 'fingerPrint',
						name: 'FingerPrint', // 등록화면
						component: () =>
							import(
								/* webpackChunkName: "register" */ '@/views/register/sub/FingerPrint.vue'
							),
					},
					{
						path: 'userInfo',
						name: 'UserInfo', // 사용자 정보 입력 화면
						component: () =>
							import(
								/* webpackChunkName: "register" */ '@/views/register/sub/UserInfo.vue'
							),
					},
					{
						path: 'complete',
						name: 'RegisterComplete', // 사용자 정보 입력 화면
						component: () =>
							import(
								/* webpackChunkName: "register" */ '@/views/register/sub/Complete.vue'
							),
					},
				],
			},
			{
				path: 'guide/registerForCorp',
				name: 'GuideRegisterForCorp', // 법인등록소개화면
				component: () =>
					import(
						/* webpackChunkName: "guide" */ '@/views/guide/RegisterGuideForCorp.vue'
					),
			},
			{
				path: 'guide/AppPermission',
				name: 'GuideAppPermission', // 권한 허용 요청화면
				component: () =>
					import(
						/* webpackChunkName: "guide" */ '@/views/guide/AppPermissionGuide.vue'
					),
			},

			{
				path: 'search/address',
				name: 'SearchAddress', // 권한 허용 요청화면
				component: () =>
					import(/* webpackChunkName: "search" */ '@/views/search/Address.vue'),
			},

			{
				path: 'tos',
				name: 'Container', // 약관동의 화면
				component: () =>
					import(/* webpackChunkName: "tos" */ '@/views/tos/Container.vue'),
				children: [
					{
						path: 'list',
						name: 'TosList',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/TosList.vue'
							),
					},
					{
						path: 'form',
						name: 'TosForm',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/TosForm.vue'
							),
					},
					{
						path: 'form/my',
						name: 'MyTosForm',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/MyTosForm.vue'
							),
					},
					{
						path: 'postAppTerms',
						name: 'PostAppTerms',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/PostAppTerms.vue'
							),
					},
					{
						path: 'privacyConsent',
						name: 'PrivacyConsent',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/PrivacyConsent.vue'
							),
					},
					{
						path: '3rdConsent',
						name: '3rdConsent',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/3rdConsent.vue'
							),
					},
					{
						path: 'marketingConsent',
						name: 'MarketingConsent',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/MarketingConsent.vue'
							),
					},
					{
						path: 'postAppConsent',
						name: 'PostAppConsent',
						component: () =>
							import(
								/* webpackChunkName: "tos" */ '@/views/tos/sub/PostAppConsent.vue'
							),
					},
				],
			},

			{
				path: 'home',
				component: () =>
					import(/* webpackChunkName: "home" */ '@/views/home/Container.vue'),
				children: [
					{
						path: 'user',
						name: 'UserHome', // 소개화면
						component: () =>
							import(
								/* webpackChunkName: "home" */ '@/views/home/UserHome.vue'
							),
					},
					{
						path: 'corp',
						name: 'CorpHome', // 소개화면
						component: () =>
							import(
								/* webpackChunkName: "home" */ '@/views/home/CorpHome.vue'
							),
					},
				],
			},

			{
				path: 'alram',
				name: 'Alram', // 소개화면
				component: () =>
					import(/* webpackChunkName: "home" */ '@/views/alram/Alram.vue'),
			},

			{
				path: 'service',
				component: () =>
					import(
						/* webpackChunkName: "service" */ '@/views/more/Container.vue'
					),
				children: [
					{
						path: '',
						name: 'Service',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/Service.vue'
							),
					},
					{
						path: 'list/post',
						name: 'PostList',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/sub/PostList.vue'
							),
					},
					{
						path: 'list/shopping',
						name: 'ShoppingList',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/sub/ShoppingList.vue'
							),
					},
					{
						path: 'list/bank',
						name: 'BankList',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/sub/BankList.vue'
							),
					},
					{
						path: 'list/fund',
						name: 'FundList',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/sub/FundList.vue'
							),
					},
					{
						path: 'list/insulance',
						name: 'InsulanceList',
						component: () =>
							import(
								/* webpackChunkName: "service" */ '@/views/service/sub/InsulanceList.vue'
							),
					},
				],
			},

			{
				path: 'my',
				component: () =>
					import(/* webpackChunkName: "my" */ '@/views/my/Container.vue'),
				children: [
					{
						path: '',
						name: 'My',
						component: () =>
							import(/* webpackChunkName: "my" */ '@/views/my/My.vue'),
					},
				],
			},

			{
				path: 'more',
				component: () =>
					import(/* webpackChunkName: "more" */ '@/views/more/Container.vue'),
				children: [
					{
						path: '',
						name: 'More',
						component: () =>
							import(/* webpackChunkName: "more" */ '@/views/more/More.vue'),
					},
					{
						path: 'settings',
						name: 'Settings',
						component: () =>
							import(
								/* webpackChunkName: "more" */ '@/views/more/Settings.vue'
							),
					},
					{
						path: 'notice',
						name: 'Notice',
						component: () =>
							import(/* webpackChunkName: "more" */ '@/views/more/Notice.vue'),
					},
					{
						path: 'qna',
						name: 'QnA',
						component: () =>
							import(/* webpackChunkName: "more" */ '@/views/more/QnA.vue'),
					},
					{
						path: 'usage',
						name: 'Usage',
						component: () =>
							import(/* webpackChunkName: "more" */ '@/views/more/Usage.vue'),
					},
					{
						path: 'withdrawal',
						name: 'Withdrawal',
						component: () =>
							import(
								/* webpackChunkName: "more" */ '@/views/more/Withdrawal.vue'
							),
					},
				],
			},
		],
	},

	{
		path: '/error',
		name: 'Error',
		component: () =>
			import(/* webpackChunkName: "error" */ '../views/Error.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
