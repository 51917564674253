export const API_URL = process.env.VUE_APP_API_URL;
export const DEPLOY_MODE = process.env.NODE_ENV === 'development' ? 'D' : 'P';

export const JUSO_API_URL = process.env.VUE_APP_JUSO_API_URL;
export const JUSO_API_KEY = process.env.VUE_APP_JUSO_API_KEY;

export const ERROR_CODE = {
	NETWORK_ERROR: 'Network Error',
	SESSION_ERROR: 'Session Error',
	SERVER_ERROR: 'Server Error',
	INTERNAL_ERROR: 'Internal Error',
};

export const VIEW_STATE = {
	LOADING: 0,
	COMPLETE: 1,
	ERROR: 2,
};
