<template>
	<span />
</template>

<script>
import { mapMutations } from 'vuex';

import {
	KPIC_dispatchDID,
	KPIC_dispatchRequestAuth,
	// KPIC_dispatchCloseApp,
} from '@/util/appBridge';

export default {
	name: 'KPICApp',

	created() {
		this.initState();

		const shownIntoroduce = localStorage.getItem('shownIntoroduce');
		if (!shownIntoroduce) {
			this.$router.replace({
				name: 'Introduce',
			});
			return;
		}

		console.debug('KPICApp =======================================');

		// app에 DID 정보가 있는지 확인한다.
		KPIC_dispatchDID();
		console.debug('dispatchDID', 'dispatchDIDCallback({RESULT: "OK"})');

		window.dispatchDIDCallback = res => {
			window.dispatchDIDCallback = null;
			console.debug('dispatchDIDCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					KPIC_dispatchRequestAuth();
					console.debug(
						'dispatRequestAuth',
						'dispatchRequestAuthCallback({RESULT: "OK", DATA: {userType: 0}})',
					);
				} else {
					// DID가 없으므로 등록페이지로 이동
					this.$router.replace({
						name: 'RegisterPostService',
						params: {},
					});
				}
			}
		};

		window.dispatchRequestAuthCallback = res => {
			window.dispatchRequestAuthCallback = null;
			console.debug('dispatchRequestAuthCallback', res);
			if (res && res.RESULT) {
				if (res.RESULT === 'OK') {
					// 법인회원인지 개인회원인지 분기 처리한다.
					const isCorpUser = res.DATA.userType === 0 ? false : true;

					if (isCorpUser) {
						console.debug('법인회원 화면으로 가자!!!');
						this.$router.replace({ name: 'LoginForCorp' });
					} else {
						console.debug('개인회원 화면으로 가자!!!');
						this.$router.replace({ name: 'UserHome' });
					}
				} else {
					// 인증실패
					// 앱을 종료한다.
					// KPIC_dispatchCloseApp();
				}
			}
		};
	},

	methods: {
		...mapMutations({ saveMemberInfo: 'saveMemberInfo' }),
		...mapMutations({ initState: 'initState' }),
	},
};
</script>
