import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueMoment from 'vue-moment';
import PortalVue from 'portal-vue';

import ApiService from '@/api';

Vue.config.productionTip = false;

Vue.use(VueMoment);
Vue.use(PortalVue);
ApiService.init();

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
