import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { API_URL, ERROR_CODE } from '@/constants';
import store from '@/store';

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = API_URL;
		Vue.axios.interceptors.request.use(
			function (config) {
				console.debug('interceptor', config);
				// config.params['EXTRLACCES_CRTFCNO'] = '823eid#$*@#231(#2DD%';
				if (config.method !== 'get') {
					config.params['EXTRLACCES_CRTFCNO'] = store.getters.serviceId;
				}

				// config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
				// config.headers['Access-Control-Allow-Origin'] = '*';
				// config.headers['Accept'] = '*/*';
				return config;
			},
			function (error) {
				// Do something with request error
				return Promise.reject(error);
			},
		);
		// Add a response interceptor
		Vue.axios.interceptors.response.use(
			function (response) {
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				console.debug('api interceptor', response);
				let data = response.data;

				// 세션 문제
				if (data.status === '999') {
					return Promise.reject(new Error(ERROR_CODE.SESSION_ERROR));
				}

				// 서버 내부 오류 문제
				if (data.status === 'fail') {
					return Promise.reject(new Error(ERROR_CODE.SERVER_ERROR));
				}

				// if (response.config.url === 'garageApi/qu-idea-list') {
				//   return Promise.reject(new Error(ERROR_CODE.SERVER_ERROR));
				// }

				return response;
				// return Promise.reject(new Error(ERROR_CODE.SERVER_ERROR));
			},
			function (error) {
				// Any status codes that falls outside the range of 2xx cause this function to trigger
				// Do something with response error
				return Promise.reject(error);
			},
		);
	},

	axoisConfig() {
		return {
			withCredentials: true,
			timeout: 10000,
			headers: {
				'Content-Type': 'application/json; charset = utf-8',
				'Access-Control-Allow-Origin': '*',
			},
		};
	},
	// eslint-disable-next-line no-unused-vars
	post(resource, params) {
		return Vue.axios.post(`${resource}`, null, {
			params: params,
		});
	},
};

export default ApiService;

export const KPICApi = {
	/**
	 * 개인회원 조회
	 * @returns
	 */
	async fetchMemberInfo(kpdid) {
		const param = { kpdid: kpdid };
		return await ApiService.post(
			'/api/ext/v1/kpdid/getKpdidMberIndvdlIssu',
			param,
		);
	},

	/**
	 * 회원개인 얼굴인식 저장
	 * @returns
	 */
	async saveMemberFaceFido() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
			facerecogUseatCd: '얼굴인식 사용여부 코드',
			kpdid: 'KP-DID',
		};
		return await ApiService.post(
			'/api/ext/v1/kpdid/modMberIndvdlFacerecogUseat',
			param,
		);
	},

	/**
	 * 회원개인 지문인식 저장
	 * @returns
	 */
	async saveMemberFingerFido() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
			fngprtrecogUseatCd: '지문인식 사용여부 코드',
			kpdid: 'KP-DID',
		};
		return await ApiService.post(
			'/api/ext/v1/kpdid/modMberIndvdlFngprtrecogUseat',
			param,
		);
	},

	/**
	 * 회원개인 알림 저장
	 * @returns
	 */
	async saveMemberAlram(mberId, ntcnUseatCd) {
		const param = {
			mberId: mberId,
			ntcnUseatCd: ntcnUseatCd,
		};
		console.debug('saveMemberAlram', param);
		return await ApiService.post(
			'/api/ext/v1/kpdid/modMberIndvdlNtcnUseat',
			param,
		);
	},

	/**
	 * 회원개인 탈퇴
	 * @returns
	 */
	async deactiveMemberAccount(mberId) {
		const param = {
			mberId: mberId,
		};
		return await ApiService.post(
			'/api/ext/v1/kpdid/modMberIndvdlPrcSttus',
			param,
		);
	},

	/**
	 * 회원개인 등록
	 * @returns
	 */
	async registerMemberInfo(param) {
		return await ApiService.post('/api/ext/v1/kpdid/regKpdidMberIndvdl', param);
	},

	/**
	 * 공지사항 조회
	 * @returns
	 */
	async fetchNoticeDetail(writngDe, writngSn) {
		const param = {
			writngDe: writngDe,
			writngSn: writngSn,
		};
		return await ApiService.post('/api/ext/v1/oper/getNotice', param);
	},

	/**
	 * 공지사항 목록조회
	 * @returns
	 */
	async fetchNoticeList() {
		const param = {
			schExpsrtyCd: '100',
		};
		// return await ApiService.post('/api/ext/v1/oper/getNoticeList', param);
		return await ApiService.post('/api/ext/v1/oper/getNoticeList', param);
	},

	/**
	 * 약관 조회(약관 키)
	 * @returns
	 */
	async fetchTermsByTeramsId() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
			stplatId: '약관ID',
		};
		return await ApiService.post('/api/ext/v1/stplat/getStplat', param);
	},

	/**
	 * 약관 목록 조호(사용여부)
	 * @returns
	 */
	async fetchTermsAgreeList() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
		};
		return await ApiService.post('/api/ext/v1/stplat/getStplatList', param);
	},

	/**
	 * 약관 목록조회(회원개인 키)
	 * @returns
	 */
	async fetchTermsByMemberId() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
			mberId: '회원 ID',
		};
		return await ApiService.post(
			'/api/ext/v1/stplat/getStplatListForMberIndvdl',
			param,
		);
	},

	/**
	 * 주요질문 조회
	 * @returns
	 */
	async fetchFaqDetail() {
		const param = {
			EXTRLACCES_CRTFCNO: '외부접근 인증번호',
			writngDe: '작성 일자',
			writngSn: '작성 순번',
		};
		return await ApiService.post('/api/ext/v1/mainqestn/getMainqestn', param);
	},

	/**
	 * 주요질문 목록조회
	 * @returns
	 */
	async fetchFaqList() {
		const param = {};
		return await ApiService.post(
			'/api/ext/v1/mainqestn/getMainqestnList',
			param,
		);
	},

	/**
	 * 회원 가입여부 검사
	 * @param {*} brthdy
	 * @param {*} ci
	 * @param {*} mbtlnum
	 * @param {*} nm
	 * @param {*} sexdstn
	 * @returns
	 */
	async fetchCheckMemberInfo(brthdy, ci, mbtlnum, nm, sexdstn) {
		const param = {
			brthdy: brthdy,
			ci: ci,
			mbtlnum: mbtlnum,
			nm: nm,
			sexdstn: sexdstn,
		};
		return await ApiService.post(
			'/api/ext/v1/kpdid/getMberIndvdlByMoblphonSelfcrtfc',
			param,
		);
	},

	/**
	 * 회원개인 DID 갱신
	 * @param {*} kpDid
	 * @param {*} mberId
	 * @returns
	 */
	async fetchUpdateDID(kpDid, mberId) {
		const param = {
			kpDid: kpDid,
			mberId: mberId,
		};
		return await ApiService.post('/api/ext/v1/kpdid/modMberIndvdlDid', param);
	},
};
