export const DISPATH_CODE = {
	CLOSE_APP: 100,
	START_PERMISSION: 300,
	QR_READER: 500,
	REG_PASSCODE: 600,
	REG_FINGER_PRINT: 601,
	GET_LOCATION: 700,
	GET_DID: 900,
	CREATE_DID: 901,
	DELETE_DID: 902,
	GET_APP_VERSION: 1000,
	REQUEST_AUTH: 2000,
	UPDATE_AUTH_TYPE: 2001,
	GET_AUTH_TYPE: 2002,
};

export const KPIC_dispatchCloseApp = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.CLOSE_APP, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchCloseApp');
		window.appBridge.dispatch(DISPATH_CODE.CLOSE_APP, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchCloseApp');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.CLOSE_APP, data: '' }),
		);
	}
};

export const KPIC_dispatchQrReader = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.QR_READER, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchQrReader');
		window.appBridge.dispatch(DISPATH_CODE.QR_READER, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchQrReader');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.QR_READER, data: '' }),
		);
	}
};

export const KPIC_dispatchStartPermission = () => {
	console.log(
		JSON.stringify({ code: DISPATH_CODE.START_PERMISSION, data: '' }),
	);
	if (window.appBridge) {
		console.debug('android KPIC_dispatchStartPermission');
		window.appBridge.dispatch(DISPATH_CODE.START_PERMISSION, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchQrReader');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.START_PERMISSION, data: '' }),
		);
	}
};

export const KPIC_dispatchRegPassCode = passcode => {
	console.log(
		JSON.stringify({ code: DISPATH_CODE.REG_PASSCODE, data: passcode }),
	);
	if (window.appBridge) {
		console.debug('android KPIC_dispatchRegPassCode');
		window.appBridge.dispatch(DISPATH_CODE.REG_PASSCODE, passcode);
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchRegPassCode');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.REG_PASSCODE, data: passcode }),
		);
	}
};

export const KPIC_dispatchRegFingerPrint = () => {
	console.log(
		JSON.stringify({ code: DISPATH_CODE.REG_FINGER_PRINT, data: '' }),
	);
	if (window.appBridge) {
		console.debug('android KPIC_dispatchRegFingerPrint');
		window.appBridge.dispatch(DISPATH_CODE.REG_FINGER_PRINT, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchRegFingerPrint');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.REG_FINGER_PRINT, data: '' }),
		);
	}
};

export const KPIC_dispatchDID = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.GET_DID, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchDID');
		window.appBridge.dispatch(DISPATH_CODE.GET_DID, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchDID');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.GET_DID, data: '' }),
		);
	}
};

export const KPIC_dispatchAppVersion = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.GET_APP_VERSION, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchAppVersion');
		window.appBridge.dispatch(DISPATH_CODE.GET_APP_VERSION, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchAppVersion');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.GET_APP_VERSION, data: '' }),
		);
	}
};

export const KPIC_dispatchCreateDID = () => {
	console.debug(JSON.stringify({ code: DISPATH_CODE.CREATE_DID, data: '' }));
	console.debug(
		'dispatchCreateDIDCallback({RESULT: "OK", DATA: {DID: "did:koreapost:testnet:0000000000000000000000000000000000000000000000000000000000000018"}})',
	);
	if (window.appBridge) {
		console.debug('android KPIC_dispatchCreateDID');
		window.appBridge.dispatch(DISPATH_CODE.CREATE_DID, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchCreateDID');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.CREATE_DID, data: '' }),
		);
	}
};

export const KPIC_dispatchDeleteDID = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.DELETE_DID, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchDeleteDID');
		window.appBridge.dispatch(DISPATH_CODE.DELETE_DID, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchDeleteDID');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.DELETE_DID, data: '' }),
		);
	}
};

export const KPIC_dispatchRequestAuth = () => {
	console.log(JSON.stringify({ code: DISPATH_CODE.REQUEST_AUTH, data: '' }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchRequestAuth');
		window.appBridge.dispatch(DISPATH_CODE.REQUEST_AUTH, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchRequestAuth');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.REQUEST_AUTH, data: '' }),
		);
	}
};

export const KPIC_dispatchUpdateAuthType = bool => {
	console.log(
		JSON.stringify({ code: DISPATH_CODE.UPDATE_AUTH_TYPE, data: bool }),
	);
	if (window.appBridge) {
		console.debug('android KPIC_dispatchUpdateAuthType');
		window.appBridge.dispatch(DISPATH_CODE.UPDATE_AUTH_TYPE, bool);
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchUpdateAuthType');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.UPDATE_AUTH_TYPE, data: bool }),
		);
	}
};

export const KPIC_dispatchGetAuthType = bool => {
	console.log(JSON.stringify({ code: DISPATH_CODE.GET_AUTH_TYPE }));
	if (window.appBridge) {
		console.debug('android KPIC_dispatchGetAuthType');
		window.appBridge.dispatch(DISPATH_CODE.GET_AUTH_TYPE, '');
	} else if (
		window.webkit &&
		window.webkit.messageHandlers &&
		window.webkit.messageHandlers.dispatch
	) {
		console.debug('ios KPIC_dispatchGetAuthType');
		window.webkit.messageHandlers.dispatch.postMessage(
			JSON.stringify({ code: DISPATH_CODE.GET_AUTH_TYPE, data: bool }),
		);
	}
};
