import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';

import { KPICApi } from '@/api';

import {
	KPIC_dispatchRegPassCode,
	KPIC_dispatchRegFingerPrint,
	KPIC_dispatchDeleteDID,
	KPIC_dispatchCreateDID,
} from '@/util/appBridge';

Vue.use(Vuex);

const getDefaultState = () => {
	return {
		showLoadingProgress: false,
		toast: {
			modalToastShowState: false,
			msg: null,
		},

		serviceId: '823eid#$*@#231(#2DD%',

		popupStack: [],

		windowPopup: null,

		register: {
			currentStep: 0,
			hasPermission: false,
			hasIndentity: false,
			hasAgreeTerm: false,
			hasUserInfo: false,
			hasPassCode: false,
			hasFingerPrint: 0, // 0: 등록전, 1: 등록후, 2: 등록 건너뛰기

			terms: {
				check01: false,
				check02: false,
				check03: false,
				check04: false,
				check05: false,
				check06: false,
				check07: false,
				check08: false,
				check09: false,
				check10: false,
				checkAll: false,
			},

			userInfo: {
				name: '',
				sex: '',
				birth: '',
				hp: '',
				email1: '',
				email2: '',
				address: '',
				addressDetail: '',
				ci: '',
				di: '',
				national: '', // 국적
			},
		},

		memberInfo: {},
	};
};

const state = getDefaultState();

const getters = {
	showLoadingProgress() {
		return state.showLoadingProgress;
	},

	modalToastState() {
		return state.toast;
	},

	currentPop() {
		const data = state.popupStack[state.popupStack.length - 1];
		return data;
	},

	popOpenState: state => popName => {
		return state.popupStack.find(item => item.name === popName) !== undefined
			? true
			: false;
		// const data = state.popupStack[state.popupStack.length - 1];
		// return data;
	},

	popCount() {
		return state.popupStack.length;
	},

	registerState: state => {
		return state.register;
	},

	serviceId: state => {
		return state.serviceId;
	},

	memberInfo() {
		return state.memberInfo;
	},
};

const actions = {
	// eslint-disable-next-line no-unused-vars
	goBack(context, payload) {
		router.go(-1);
	},

	// eslint-disable-next-line no-unused-vars
	doPostSeriveRegister(context, payload) {
		//
		console.debug(
			'doPostSeriveRegister',
			context.getters.registerState,
			router,
		);

		if (!context.getters.registerState.hasPermission) {
			router.push({ name: 'GuideAppPermission' });
			return;
		}

		if (
			context.getters.registerState.hasPermission &&
			!context.getters.registerState.hasIndentity
		) {
			KPIC_dispatchCreateDID();

			window.dispatchCreateDIDCallback = res => {
				window.dispatchCreateDIDCallback = null;
				console.debug('doDIDCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						const kpicDID = res.DATA.DID;
						context.commit('openCertWinPopup');

						// 휴대폰 본인인증 콜백 설정
						const sampleData = {
							ci: '1111111111111111111111111111111111111111111111111111111111111111133111111111111111111112',
							di: '1111111111111111111111111111111111111111111111111111111111111111',
							name: '홍갈덩',
							sex: 'M',
							hp: '010-0010-0001',
							birth: '19980101',
							national: 1,
						};
						console.debug(
							'register',
							'hpCertCallback({RESULT: "OK", DATA: ' +
								JSON.stringify(sampleData) +
								'})',
						);

						window.hpCertCallback = res => {
							window.hpCertCallback = null;
							console.debug('hpCertCallback', res);
							if (res && res.RESULT) {
								if (res.RESULT === 'OK') {
									context.commit('closeWinPop');
									context.commit('saveIndentityState', true);
									context.commit('saveUserIdentity', res.DATA);

									KPICApi.fetchCheckMemberInfo(
										context.getters.registerState.userInfo.birth.replace(
											/-/g,
											'',
										),
										context.getters.registerState.userInfo.ci,
										context.getters.registerState.userInfo.hp.replace(/-/g, ''),
										context.getters.registerState.userInfo.name,
										context.getters.registerState.userInfo.sex,
									)
										.then(({ data }) => {
											if (data.RESULT_CD === '1') {
												KPICApi.fetchUpdateDID(
													kpicDID,
													data.apiKpdidMberIndvdl.mberId,
												)
													.then(({ data }) => {
														if (data.RESULT_CD === '1') {
															context.commit('saveUserInfoState', true);
															context.commit('saveAgreeTermState', true);
															context.dispatch('doPostSeriveRegister');
														} else {
															//
														}
													})
													// eslint-disable-next-line no-unused-vars
													.catch(err => {
														//
													});
											} else {
												context.dispatch('doPostSeriveRegister');
											}
										})
										// eslint-disable-next-line no-unused-vars
										.catch(err => {
											//
										});
								}
							}
						};
					} else {
						//
					}
				} else {
					//
				}
			};
			// window.open('http://naver.com', '_blank');

			// //휴대폰 본인인증 화면 호출
			// //TODO 휴대혼 본인인증 화면 호출한다.

			// context.commit('openCertWinPopup');

			// KPIC_dispatCheckUserIdentity();

			return;
		}

		if (
			context.getters.registerState.hasPermission &&
			context.getters.registerState.hasIndentity &&
			!context.getters.registerState.hasAgreeTerm
		) {
			router.push({ name: 'TosForm' });
		}

		if (
			context.getters.registerState.hasPermission &&
			context.getters.registerState.hasIndentity &&
			context.getters.registerState.hasAgreeTerm &&
			!context.getters.registerState.hasUserInfo
		) {
			router.push({ name: 'UserInfo' });
		}

		if (
			context.getters.registerState.hasPermission &&
			context.getters.registerState.hasIndentity &&
			context.getters.registerState.hasAgreeTerm &&
			context.getters.registerState.hasUserInfo &&
			!context.getters.registerState.hasPassCode
		) {
			window.dispatchRegPassCodeCallback = res => {
				window.dispatchRegPassCodeCallback = null;
				console.debug('dispatRegPassCodeCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						context.commit('savePassCodeState', true);
						context.dispatch('doPostSeriveRegister');
					} else if (res.RESULT === 'NR') {
						// 패스코드 설정을 할수 없는 디바이스
						window.dispatchDeleteDIDCallback = res => {
							window.dispatchDeleteDIDCallback = null;
							console.debug('dispatchDeleteDIDCallback', res);
							if (res && res.RESULT) {
								if (res.RESULT === 'OK') {
									router.replace({ path: '/' });
								}
							}
						};
						KPIC_dispatchDeleteDID();
					}
				}
			};

			KPIC_dispatchRegPassCode(context.getters.registerState);
			router.push({ name: 'RegisterPassCode' });
		}

		if (
			context.getters.registerState.hasPermission &&
			context.getters.registerState.hasIndentity &&
			context.getters.registerState.hasAgreeTerm &&
			context.getters.registerState.hasUserInfo &&
			context.getters.registerState.hasPassCode &&
			context.getters.registerState.hasFingerPrint === 0
		) {
			console.debug('register', 'doRegisterFidoCallback({RESULT: "OK"})');
			window.dispatchRegFingerPrintCallback = res => {
				window.dispatchRegFingerPrintCallback = null;
				console.debug('dispatRegFingerPrintCallback', res);
				if (res && res.RESULT) {
					if (res.RESULT === 'OK') {
						context.commit('saveFingerPrintState', 1);
						context.dispatch('doPostSeriveRegister');
					} else {
						context.commit('saveFingerPrintState', 2);
						context.dispatch('doPostSeriveRegister');
					}
				}
			};
			KPIC_dispatchRegFingerPrint();
			router.push({ name: 'FingerPrint' });
		}

		// 사용자가 생체정보 등록 건너뛰기를 선택하였을시 윈도우 콜백함수를 제거한다.
		// if (context.getters.registerState.hasFingerPrint === 2) {
		// 	window.doRegisterFidoCallback = null;
		// }

		if (
			context.getters.registerState.hasPermission &&
			context.getters.registerState.hasIndentity &&
			context.getters.registerState.hasAgreeTerm &&
			context.getters.registerState.hasUserInfo &&
			context.getters.registerState.hasPassCode &&
			context.getters.registerState.hasFingerPrint !== 0
		) {
			router.push({ name: 'RegisterComplete' });
		}
	},

	// /**
	//  * 로그인 처리를 한다.
	//  * @param {*} context context
	//  * @param {*} payload { type: 'passcode': 비밀번호,'finger' : 지문인식, 'face' : 얼굴인식, data: this.passCode }
	//  */
	// // eslint-disable-next-line no-unused-vars
	// doLogin(context, payload) {
	// 	// 네이티브에 로그인 요청

	// 	console.debug(
	// 		'login',
	// 		'doLoginCallback({RESULT: "OK", DATA: {userType: 0}})',
	// 	);

	// 	KPIC_dispatchCheckPassCode();

	// 	window.doLoginCallback = res => {
	// 		window.doLoginCallback = null;
	// 		console.debug('doLoginCallback', res);
	// 		if (res && res.RESULT) {
	// 			if (res.RESULT === 'OK') {
	// 				// 법인회원인지 개인회원인지 분기 처리한다.
	// 				const isCorpUser = res.DATA.userType === 0 ? false : true;

	// 				if (isCorpUser) {
	// 					console.debug('법인회원 화면으로 가자!!!');
	// 					router.replace({ name: 'LoginForCorp' });
	// 				} else {
	// 					console.debug('개인회원 화면으로 가자!!!');
	// 					router.replace({ name: 'UserHome' });
	// 				}
	// 			} else {
	// 				//
	// 			}
	// 		}
	// 	};
	// },
};

const mutation = {
	updateLoadingProgressState(state, bool) {
		state.showLoadingProgress = bool;
	},

	updateModalState(state, msg) {
		// payload = {msg: 토스트 메세지}
		state.toast = { ...state.toast, modalToastShowState: true, msg: msg };
	},

	resetModalState(state) {
		state.toast = { ...getDefaultState().toast };
	},

	saveRegisterStepState(state, payload) {
		state.register.currentStep = payload;
	},

	savePermissionState(state, payload) {
		state.register.hasPermission = payload;
	},

	saveIndentityState(state, payload) {
		state.register.hasIndentity = payload;
	},

	saveAgreeTermState(state, payload) {
		state.register.hasAgreeTerm = payload;
	},

	saveUserInfoState(state, payload) {
		state.register.hasUserInfo = payload;
	},

	savePassCodeState(state, payload) {
		state.register.hasPassCode = payload;
	},

	saveFingerPrintState(state, payload) {
		state.register.hasFingerPrint = payload;
	},

	saveUserAddressState(state, payload) {
		state.register.userInfo.address = payload;
	},

	saveUserIdentity(state, payload) {
		state.register.userInfo.ci = payload.ci;
		state.register.userInfo.di = payload.di;
		state.register.userInfo.name = payload.name;
		state.register.userInfo.sex = payload.sex;
		state.register.userInfo.hp = payload.hp;
		state.register.userInfo.national = payload.national;
		state.register.userInfo.birth = payload.birth;
	},

	saveMemberInfo(state, payload) {
		state.memberInfo = payload;
	},

	openPopup(state, { what, callback, data }) {
		if (what === 'pointFilter') {
			state.popupStack.push({
				name: what,
				cancel: true,
				callback: callback,
				data: data,
			});
		}
	},

	closePopup(state) {
		const pop = state.popupStack[state.popupStack.length - 1];

		if (pop.cancel === true) {
			state.popupStack.pop();
		}
	},

	closeAllPopup(state) {
		state.popupStack = [];
	},

	initState(state) {
		state.register = { ...getDefaultState().register };
		state.memberInfo = { ...getDefaultState().memberInfo };
	},

	openCertWinPopup(state) {
		console.debug('openCertWinPopup', process.env.VUE_APP_HP_CERT_URL);
		const certWindowPopup = window.open(
			process.env.VUE_APP_HP_CERT_URL,
			'_blank',
			'height=' + Screen.height + ',width=' + Screen.width,
		);
		state.windowPopup = certWindowPopup;
	},

	closeWinPop(state) {
		if (state.windowPopup) {
			state.windowPopup.close();
			state.windowPopup = getDefaultState().windowPopup;
		}
	},
};

export default new Vuex.Store({
	state: state,
	mutations: mutation,
	getters: getters,
	actions: actions,
	modules: {},
});
