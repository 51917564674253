<template>
	<div>
		<router-view />
		<LoadingProgress v-if="showLoadingProgress" />
		<ModalToast
			v-if="modalToastState.modalToastShowState"
			:msg="modalToastState.msg"
			:portalTo="'modal3'"
		/>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingProgress from '@/components/LoadingProgress';
import ModalToast from '@/components/ModalToast';

export default {
	name: 'RootContainer',

	components: {
		LoadingProgress,
		ModalToast,
	},

	computed: {
		...mapGetters(['showLoadingProgress']),
		...mapGetters(['modalToastState']),
	},
};
</script>
